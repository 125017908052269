import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { CircularProgress } from '@mui/material';

import { getLocalData, setLocalData } from '@services/localStorage';
import { IsLoggedInValues } from '@constants/auth';
import { useAppDispatch } from '@store/store';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { RootState } from '@store/reducers';

import RoutesPath from './RoutesPath';
import PublicLayout from './PublicLayout';
import PrivateLayout from './PrivateLayout';

// const HomePage = lazy(() => import('modules/home/home-page'));

const RouteLayout = () => {
  const dispatch = useAppDispatch();
  const { isUserAuthenticated } = useSelector(
    (state: RootState) => state.rootReducer.userAuth
  );

  const [isAuthenticated, setIsAuthenticated] = useState(
    getLocalData(IsLoggedInValues.IS_LOGGED_IN)
  );

  useEffect(() => {
    const authenticatedState = getLocalData(IsLoggedInValues.IS_LOGGED_IN);
    if (!authenticatedState) {
      setLocalData(IsLoggedInValues.IS_LOGGED_IN, IsLoggedInValues.LOGGED_OUT);
    }
    dispatch(
      updateIsUserAuthenticated(
        authenticatedState ? authenticatedState : IsLoggedInValues.LOGGED_OUT
      )
    );
  }, []);

  useEffect(() => {
    //call a basic api and if it fails also set as logged out
    if (isUserAuthenticated === IsLoggedInValues.LOGGED_OUT) {
      setIsAuthenticated(IsLoggedInValues.LOGGED_OUT);
      setLocalData(IsLoggedInValues.IS_LOGGED_IN, IsLoggedInValues.LOGGED_OUT);
      //clear user details
    }
    //call a basic api if success set as logged in
    if (isUserAuthenticated === IsLoggedInValues.LOGGED_IN) {
      setIsAuthenticated(IsLoggedInValues.LOGGED_IN);
      setLocalData(IsLoggedInValues.IS_LOGGED_IN, IsLoggedInValues.LOGGED_IN);
    }
  }, [isUserAuthenticated]);

  return (
    <>
      {isAuthenticated && (
        <Routes>
          <Route
            path={RoutesPath.ALL}
            element={
              isAuthenticated === IsLoggedInValues.LOGGED_IN ? (
                <PrivateLayout />
              ) : (
                <PublicLayout />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default RouteLayout;
