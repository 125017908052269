import { createSlice } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  search: ''
} as ReducerStateType;

export const dealAnalyticsSlice = createSlice({
  name: 'dealAnalytics',
  initialState,
  reducers: {
    resetDealListingValues: state => ({
      ...state,
      search: ''
    }),
    updateDealAnalyticsValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetDealListingValues, updateDealAnalyticsValues } =
  dealAnalyticsSlice.actions;

export default dealAnalyticsSlice.reducer;
