import { AuthorizationValues, IsLoggedInValues } from '@constants/auth';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { removeLocalData, setLocalData } from '@services/localStorage';
import store from '@store/store';

export const logout = () => {
  setLocalData(IsLoggedInValues.IS_LOGGED_IN, IsLoggedInValues.LOGGED_OUT);
  removeLocalData(AuthorizationValues.ACCESS_TOKEN);
  removeLocalData(AuthorizationValues.REFRESH_TOKEN);
  store.dispatch(updateIsUserAuthenticated(IsLoggedInValues.LOGGED_OUT));
};
