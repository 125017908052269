import React, { FC, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { isEmpty } from 'lodash';

import { formatAmount } from '@utils/amount';
import { colors } from '@constants/colors';
import { SHORT_FIELD_MAX_LENGTH } from '@constants/common';
import AmountDisplay from '@components/amount-display/AmountDisplay';

import { RhfTextFieldProps } from './types';
import styles from './rhfTextField.module.css';

const RhfTextField: FC<RhfTextFieldProps & TextFieldProps> = props => {
  const {
    control,
    autoComplete = '',
    name = '',
    placeholder,
    rows,
    multiline,
    type = 'text',
    errors,
    onCut = () => {},
    onCopy = () => {},
    onPaste = () => {},
    isDisabled = false,
    wrapperClass = '',
    onClickTextField = () => {},
    iconEnd,
    iconStart,
    onBlur = () => {},
    onChange,
    label,
    isVerified,
    maxLength,
    description,
    showCharacterLimit,
    defaultValue,
    showCurrency,
    isIndianRupee,
    isAmountField,
    isAmountViewMode,
    // amount,
    fieldRef,
    variant = 'outlined',
    autoFocusField = false,
    formatNumberFn,
    removeBorderRadius,
    hideHelperText = false
  } = props;

  const currentValue = useWatch({ control: control, name: name });

  const [currentTextField, setCurrentTextField] = useState<string>('');

  const textFieldCustomStyles = {
    '& .MuiOutlinedInput-root': {
      paddingLeft: showCurrency || showCharacterLimit ? '12px' : '0px',
      paddingBottom:
        showCharacterLimit && Number(rows) > 1 && multiline ? '25px' : '',
      '& fieldset': {
        borderColor: isVerified ? colors.violet : colors.gray88,
        borderRadius: removeBorderRadius ? 0 : undefined
      },
      '&.Mui-focused fieldset': {
        borderRadius: removeBorderRadius ? 0 : undefined
      },
      '& .MuiInputBase-root': {
        marginBottom: '10px' // Adjust this value as needed for space below input text,
      }
    }
  };

  const onBlurTextField = () => {
    setCurrentTextField('');
  };

  const onClickAmountTextField = () => {
    setCurrentTextField(name);
  };

  const getInputItems = () => {
    if (iconEnd) {
      return (
        <InputAdornment position="start">
          <div className={showCharacterLimit ? 'mb-2' : ''}>{iconEnd}</div>
          {showCharacterLimit ? (
            <div className="absolute right-6 bottom-2 text-xs font-normal leading-3 text-gray02">{`${
              currentValue?.length ?? 0
            }/${maxLength || SHORT_FIELD_MAX_LENGTH} characters`}</div>
          ) : (
            <></>
          )}
        </InputAdornment>
      );
    }
    if (showCharacterLimit) {
      return (
        <InputAdornment
          position="end"
          sx={{
            position: 'absolute',
            bottom: 15,
            right: '12px'
          }}>
          <div className="text-xs font-normal leading-3 text-gray02">{`${
            currentValue?.length ?? 0
          }/${maxLength || SHORT_FIELD_MAX_LENGTH} characters`}</div>
        </InputAdornment>
      );
    }
    if (showCurrency) {
      return (
        <InputAdornment
          position="start"
          sx={{
            position: 'absolute',
            left: '12px'
          }}>
          {isIndianRupee ? '₹' : '$'}
        </InputAdornment>
      );
    }
    return <div></div>;
  };

  const getStartItems = () => {
    if (iconStart)
      return <InputAdornment position="start">{iconStart}</InputAdornment>;
    return <div></div>;
  };

  const onClickAmountDisplay = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setCurrentTextField(name);
    onClickTextField?.(e);
  };

  const isTextFieldDisplay = useMemo(
    () => currentTextField === name,
    [currentTextField]
  );

  const textField = () => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className={`relative ${wrapperClass}`}>
            <TextField
              autoComplete={autoComplete}
              label={label || placeholder}
              placeholder={placeholder}
              rows={rows}
              multiline={multiline}
              variant={variant}
              error={Boolean(
                !isEmpty(errors) &&
                  (errors[name] as { message?: string })?.message
              )}
              inputProps={{
                style: {
                  height: '100%',
                  padding: showCharacterLimit
                    ? '16.5px 0px 25px 14px'
                    : '16.5px 0px 16.5px 14px'
                },
                maxLength: maxLength || 250
              }}
              InputProps={{
                endAdornment: getInputItems(),
                startAdornment: getStartItems()
              }}
              InputLabelProps={{
                shrink: true
              }}
              sx={textFieldCustomStyles}
              onCut={onCut}
              onCopy={onCopy}
              onPaste={onPaste}
              className={`w-full  ${styles.container}`}
              type={type}
              disabled={isDisabled}
              onClick={e => {
                onClickTextField(e);
                isAmountField && onClickAmountTextField();
              }}
              {...field}
              onChange={onChange ? onChange : field.onChange}
              onBlur={e => {
                isAmountField && onBlurTextField();
                onBlur(e);
              }}
              defaultValue={defaultValue}
              inputRef={fieldRef}
              autoFocus={
                autoFocusField || (isTextFieldDisplay && isAmountField)
              }
              helperText={
                !hideHelperText &&
                (!isEmpty(errors) && errors[name]
                  ? (errors[name] as { message?: string })?.message
                  : description)
              }
            />
          </div>
        )}
      />
    );
  };

  if (isAmountField) {
    if (isTextFieldDisplay) return textField();
    return (
      <>
        <AmountDisplay
          amount={currentValue ?? ''}
          wrapperClass={wrapperClass}
          formatFunction={formatNumberFn ? formatNumberFn : formatAmount}
          onClickAmountDisplay={onClickAmountDisplay}
          label={label || placeholder}
          description={description ?? ''}
          hasError={Boolean(
            !isEmpty(errors) && (errors[name] as { message?: string })?.message
          )}
          errors={errors ?? {}}
          name={name}
          placeholder={placeholder}
          isDisabled={isAmountViewMode}
        />
      </>
    );
  }
  return textField();
};

export default RhfTextField;
