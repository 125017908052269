import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { colors } from '@constants/colors';

import { CustomButtonPropsType } from './types';

const CustomButton: FC<CustomButtonPropsType> = ({
  onClick,
  fullWidth = false,
  label,
  variant = 'contained',
  isLoading,
  isDisabled,
  rightWidget,
  leftWidget,
  customClassname
}) => {
  const buttonStyle = {
    backgroundColor: isDisabled ? colors.gray : colors.primaryBlue,
    color: colors.white
  };

  const oulinedButtonStyle = {
    backgroundColor: 'transparent',
    color: isDisabled ? colors.gray : colors.primaryBlue
  };
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      style={variant == 'contained' ? buttonStyle : oulinedButtonStyle}
      className={`1.25xl:h-[42px] h-[30px] ${customClassname}`}
      disabled={isDisabled}>
      {isLoading ? (
        <CircularProgress size="1rem" color="inherit" />
      ) : (
        <>
          {leftWidget}
          {label}
          {rightWidget}
        </>
      )}
    </Button>
  );
};

export default CustomButton;
