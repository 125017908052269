import { CreateTeamFormFieldValues } from './types';

export const TeamMemberTableLimit = 5;
export const InvestorsTableLimit = 5;

export enum InvestorType {
  SuperAngelInvestor = 'SuperAngel',
  InstitutionalInvestor = 'InstitutionalInvestor'
}

export enum OpportunityType {
  Waitlist = 'WaitList',
  Wishlist = 'WishList',
  NotOpportunity = 'NotOpportunity'
}

export const FIELD_TO_VALUE_MAPPER = {
  companyRegisteredName: 'Company Registered Name',
  cin: 'CIN',
  registrationNumber: 'Registration Number',
  incorporationCountry: 'Incorporation Country',
  currency: 'Currency',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  city: 'City',
  state: 'State',
  country: 'Country',
  pin: 'Pin',
  timezone: 'Timezone',
  companyBrandName: 'Company Brand Name',
  website: 'Website',
  companyStage: 'Company Stage',
  sector: 'Sector',
  companyOneLiner: 'Company One Liner',
  detailedDescription: 'Detailed Description',
  companyEmailDomains: 'Company Email Domains',
  hexValue: 'HexValue'
} as { [x: string]: string };

export const FIELD_TO_TAB_MAPPER = {
  0: [
    //Legal info
    'companyRegisteredName',
    'cin',
    'registrationNumber',
    'incorporationCountry',
    'currency',
    //Address
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'country',
    'pin',
    'timezone',
    //Brand
    'companyBrandName',
    'website',
    'companyStage',
    'sector',
    'companyOneLiner',
    'detailedDescription',
    'companyEmailDomains',
    'hexValue'
  ],
  1: []
} as { [x: string]: string[] };

export const createCompanyFormDefaultValues = {
  id: '',
  status: '',
  //Legal info
  companyRegisteredName: '',
  cin: '',
  registrationNumber: '',
  //Brand
  companyBrandName: '',
  companyLogo: { file: undefined, path: '' },
  companyStage: '',
  sector: '',
  companyOneLiner: '',
  hexValue: ''
};

export const CreateTeamFormFieldDefaultValues: CreateTeamFormFieldValues = {
  teamMemberName: '',
  teamMemberEmail: '',
  teamMemberSecondaryEmail: '',
  teamMemberLinkedInUrl: '',
  teamMemberDesignation: '',
  teamMemberDescription: '',
  teamMemberRole: '',
  teamMemberExperience: '',
  teamMemberProfileImage: { file: undefined, path: '' },
  teamMemberCoverImage: { file: undefined, path: '' },
  teamMemberCreatedAt: '',
  teamMemberOrder: 0
};

export const CreateAngelInvestorFormFieldDefaultValues = {
  angelInvestorName: '',
  angelInvestorURL: '',
  angelInvestorDesignation: '',
  angelInvestorDescription: '',
  angelInvestorLogo: { file: undefined, path: '' }
};

export const CreateInstituitionalInvestorFieldDefaultValues = {
  instituitonalInvestorName: '',
  instituitonalInvestorURL: '',
  instituitonalInvestorDescription: '',
  instituitonalInvestorLogo: { file: undefined, path: '' }
};

export const CreateProductFormDefaultFieldValues = {
  products: [
    {
      productId: '',
      productName: '',
      productStatus: '',
      productDescription: '',
      iosAppLink: '',
      webAppLink: '',
      androidAppLink: '',
      appScreenshots: [
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' }
      ],
      webScreenshots: [
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' },
        { id: '', file: undefined, path: '', fileName: '' }
      ]
    }
  ]
};

export const FIELD_TO_TAB_MAPPER_TEAM_MEMBER = {
  0: [
    'teamMemberName',
    'teamMemberEmail',
    'teamMemberSecondaryEmail',
    'teamMemberLinkedInUrl',
    'teamMemberDesignation',
    'teamMemberDescription',
    'teamMemberRole',
    'teamMemberExperience'
  ],
  1: ['teamMemberProfileImage', 'teamMemberCoverImage']
};

export const FIELD_TO_TAB_MAPPER_ANGEL_INVESTOR = {
  '-1': ['angelInvestorId'],
  0: [
    'angelInvestorName',
    'angelInvestorURL',
    'angelInvestorDesignation',
    'angelInvestorDescription'
  ],
  1: ['angelInvestorLogo']
};

export const FIELD_TO_TAB_MAPPER_INSTITUITIONAL_INVESTOR = {
  '-1': ['instituitonalInvestorId'],
  0: [
    'instituitonalInvestorName',
    'instituitonalInvestorURL',
    'instituitonalInvestorDescription',
    'instituitionalInvestorDesignation'
  ],
  1: ['instituitonalInvestorLogo']
};

export enum SectionMapper {
  legalInfo = 'legalInfo',
  address = 'address',
  brand = 'brand',
  product = 'product',
  competitors = 'competitors'
}

export enum StageValues {
  EarlyStage = 'Early stage',
  GrowthStage = 'Growth stage',
  Unicorn = 'Unicorn'
}

export const CreateCompetitorFormDefaultFieldValues = {
  competitors: [
    {
      id: '',
      companyName: '',
      companyLogo: { file: undefined, path: '' }
    }
  ]
};
