import { ApiTags } from '@constants/common';
import { GenericApiResponse } from '@type/general';
import restApi from '@services/api';

import {
  ConfirmPaymentRequestType,
  DealAnalyticsDetails,
  DownloadReportResponseType,
  DealsAnalyticsSummaryResponseType,
  DownloadDocumentsPollRequest,
  DownloadDocumentsPollResponse,
  DownloadDocumentsRequest,
  DownloadDocumentsResponse,
  DropCommitRequest,
  DropCommitResponse,
  GetDealAnalyticsDetailsResponse,
  Payments,
  PaymentsHistoryReq,
  PollResponse,
  UnitCertificateGenerationRequest
} from './types';

const GetDealsApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getDealAnalyticsDetails: builder.query<
      DealAnalyticsDetails[],
      { id: string; searchTerm: string }
    >({
      query: payload => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/deal/analytics/${payload.id}${
          payload.searchTerm ? `?search=${payload.searchTerm}` : ''
        } `,
        method: 'GET'
      }),
      providesTags: [
        ApiTags.dealList,
        ApiTags.recordInvestment,
        ApiTags.dealInvite,
        ApiTags.dropCommit,
        ApiTags.activateCommit
      ],
      transformResponse: (response: GetDealAnalyticsDetailsResponse) =>
        response.result.Data
    }),
    getDealsAnalyticsSummary: builder.query<
      DealsAnalyticsSummaryResponseType,
      { dealid: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealid}/analytics/summary`,
        method: 'GET'
      }),
      providesTags: [
        ApiTags.dealList,
        ApiTags.recordInvestment,
        ApiTags.dropCommit,
        ApiTags.activateCommit,
        ApiTags.dealInvite
      ],
      transformResponse: (response: DealsAnalyticsSummaryResponseType) =>
        response
    }),
    getAnalyticsReport: builder.query<
      DownloadReportResponseType,
      { dealid: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealid}/analytics/report`,
        method: 'GET'
      }),
      transformResponse: (response: DownloadReportResponseType) => response
    }),
    generateReportPoll: builder.query<
      GenericApiResponse<PollResponse>,
      { jobId: string }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/generate-report-poll/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<PollResponse>) =>
        response
    }),
    confirmPayment: builder.mutation<
      GenericApiResponse<string>,
      ConfirmPaymentRequestType
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/utils/payment/manual/confirm`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [ApiTags.recordInvestment],
      transformResponse: (response: GenericApiResponse<string>) => response
    }),
    GenerateUnitCertificate: builder.mutation<
      GenericApiResponse<string>,
      UnitCertificateGenerationRequest
    >({
      query: payload => ({
        // eslint-disable-next-line max-len
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/user/${payload.userId}/deal/${payload.dealId}/unit-certificate/generate`,
        method: 'POST'
      })
    }),
    getPaymentsHistory: builder.query<
      GenericApiResponse<Payments[]>,
      PaymentsHistoryReq
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/user/${payload.userId}/deal/${payload.dealId}/payments`,
        method: 'GET'
      }),
      providesTags: [ApiTags.recordInvestment],
      transformResponse: (response: GenericApiResponse<Payments[]>) => response
    }),
    downloadDocuments: builder.query<
      DownloadDocumentsResponse,
      DownloadDocumentsRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealId}/download-documents`,
        method: 'GET'
      }),
      transformResponse: (response: DownloadDocumentsResponse) => response
    }),
    polldownloadedDocuments: builder.query<
      DownloadDocumentsPollResponse,
      DownloadDocumentsPollRequest
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/download-documents-poll/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: DownloadDocumentsPollResponse) => response
    }),
    dropCommit: builder.mutation<DropCommitResponse, DropCommitRequest>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealId}/user/${payload.userId}/drop-commit`,
        method: 'PUT'
      }),
      invalidatesTags: [ApiTags.dropCommit],
      transformResponse: (response: DropCommitResponse) => response
    }),
    activateCommit: builder.mutation<DropCommitResponse, DropCommitRequest>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/${payload.dealId}/user/${payload.userId}/activate-commit`,
        method: 'PUT'
      }),
      invalidatesTags: [ApiTags.activateCommit],
      transformResponse: (response: DropCommitResponse) => response
    }),
    getDownloadDealInviteesJobId: builder.mutation<
      GenericApiResponse<{
        job_id: string;
      }>,
      { dealId: string }
    >({
      query: ({ dealId }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/invite/status/${dealId}/export`,
        method: 'POST',
        body: {
          format: 'excel'
        }
      }),
      transformResponse: (
        response: GenericApiResponse<{
          job_id: string;
        }>
      ) => response
    }),
    getDealInviteesDowndloadUrl: builder.query<
      GenericApiResponse<{
        signed_url: string;
        status: string;
      }>,
      {
        jobId: string;
        dealId: string;
      }
    >({
      query: ({ dealId, jobId }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/invite/status/${dealId}/export/${jobId}`,
        method: 'GET'
      }),
      transformResponse: (
        response: GenericApiResponse<{
          signed_url: string;
          status: string;
        }>
      ) => response
    })
  })
});

export const {
  useLazyGetDealAnalyticsDetailsQuery,
  useLazyGetAnalyticsReportQuery,
  useLazyGenerateReportPollQuery,
  useGenerateReportPollQuery,
  useConfirmPaymentMutation,
  useGetDealAnalyticsDetailsQuery,
  useGetDealsAnalyticsSummaryQuery,
  useGenerateUnitCertificateMutation,
  useGetPaymentsHistoryQuery,
  useLazyDownloadDocumentsQuery,
  useLazyPolldownloadedDocumentsQuery,
  useDropCommitMutation,
  useActivateCommitMutation,
  useGetDownloadDealInviteesJobIdMutation,
  useLazyGetDealInviteesDowndloadUrlQuery
} = GetDealsApi;
