/* eslint-disable tailwindcss/no-custom-classname */
import { FC } from 'react';
import {
  Link,
  Breadcrumbs as MuiBBreadcrumbs,
  Typography
} from '@mui/material';

import { BreadcrumbsProps } from './types';

const Breadcrumbs: FC<BreadcrumbsProps> = props => {
  const { data, separator = '>' } = props;

  const generateBreadcrumbsData = () =>
    data.map((breadcrumbsData, index) => {
      if (breadcrumbsData.handleClick) {
        return (
          <Link
            underline="hover"
            key={index}
            className={'text-base cursor-pointer '}
            color="black"
            onClick={breadcrumbsData.handleClick}>
            {breadcrumbsData.label}
          </Link>
        );
      }
      return (
        <Typography
          key={index}
          className={`text-base font-normal text-black  ${
            index && 'text-opacity-60'
          }`}>
          {breadcrumbsData.label}
        </Typography>
      );
    });

  return (
    <MuiBBreadcrumbs separator={separator}>
      {generateBreadcrumbsData()}
    </MuiBBreadcrumbs>
  );
};

export default Breadcrumbs;
