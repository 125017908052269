import { useEffect, useState } from 'react';

import {
  useLazyGenerateReportPollQuery,
  useLazyGetAnalyticsReportQuery
} from '@modules/deals/deal-analytics/api';
import { handleDownloadFile } from '@utils/common';
import { ToastTypes } from '@type/toast';
import { translate } from '@utils/locale';
import { API_RESPONSE_STATUS } from '@constants/common';
import { useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import { useAppDispatch } from '@store/store';
import { showGenericErrorToast } from '@utils/error';
import {
  updateDownloadAnalyticsDealId,
  updateDownloadAnalyticsIsLoading
} from '@reducers/downloadAnalyticsReport';
import displayToast from '@components/toast/customToast';

const DownloadAnalyticsReport = () => {
  const dispatch = useAppDispatch();
  const { dealId } = useSelector(
    (state: RootState) => state.rootReducer.downloadAnalyticsReport
  );

  const [pollingInterval, setPollingInterval] = useState<number>(3000);
  const [jobID, setJobId] = useState('');

  const [
    getAnalyticsReport,
    { data: analyticsReportResult, error: analyticsReportError }
  ] = useLazyGetAnalyticsReportQuery();
  const [generateReportPoll, { data: pollStatus, error: pollStatusError }] =
    useLazyGenerateReportPollQuery();

  const onGeneratePoll = (jobId: string) => {
    generateReportPoll({
      jobId: jobId
    });
  };

  const updateIsLoading = (isLoading: boolean) => {
    dispatch(updateDownloadAnalyticsIsLoading(isLoading));
  };

  useEffect(() => {
    if (dealId) {
      getAnalyticsReport({ dealid: dealId });
      updateIsLoading(true);
      setPollingInterval(3000);
    }
  }, [dealId]);

  useEffect(() => {
    if (analyticsReportResult?.status === API_RESPONSE_STATUS.OK) {
      onGeneratePoll(analyticsReportResult.result.job_id);
      setJobId(analyticsReportResult.result.job_id);
    } else {
      updateIsLoading(false);
    }
  }, [analyticsReportResult]);

  useEffect(() => {
    if (analyticsReportError || pollStatusError) {
      dispatch(updateDownloadAnalyticsDealId(''));
      updateIsLoading(false);
      setPollingInterval(0);
      setJobId('');
      displayToast(ToastTypes.ERROR, translate('errorFallbackMessage'));
    }
  }, [analyticsReportError, pollStatusError]);

  useEffect(() => {
    if (pollStatus && analyticsReportResult) {
      if (pollStatus?.result?.status === 'success') {
        displayToast(ToastTypes.SUCCESS, 'Report generated successfully');
        handleDownloadFile(pollStatus.result.signed_url);
        dispatch(updateDownloadAnalyticsDealId(''));
        updateIsLoading(false);
        setPollingInterval(0);
        setJobId('');
      } else if (pollStatus?.result.status !== 'processing') {
        showGenericErrorToast(pollStatus.error);
        //TODO: show proper error messages frm BE
        dispatch(updateDownloadAnalyticsDealId(''));
        updateIsLoading(false);
        setPollingInterval(0);
        setJobId('');
      }
    }
  }, [pollStatus]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (jobID && pollingInterval) {
      interval = setInterval(() => onGeneratePoll(jobID), pollingInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [jobID, pollingInterval]);

  return {};
};

export default DownloadAnalyticsReport;
