import {
  useMemo,
  useState,
  MouseEvent,
  ChangeEvent,
  useEffect,
  useRef
} from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { PaginationValues } from '@constants/common';
import { descendingComparator } from '@utils/common';

import { Order, TableProps } from './types';
import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  const stringComparator = (strA: string, strB: string): number => {
    const lowerA = (strA as string).toLowerCase();
    const lowerB = (strB as string).toLowerCase();
    if (lowerA < lowerB) return 1;
    if (lowerA > lowerB) return -1;
    return 0;
  };

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, stringComparator)
    : (a, b) => -descendingComparator(a, b, orderBy, stringComparator);
}

const CustomTable = ({
  tableHeaders,
  tableData,
  processTableData,
  isLoading = false,
  hideCheckbox = false,
  disableSort = false,
  disablePagination = false,
  elevation = 1,
  shouldWrapContent = false,
  customOrderValue,
  customSortValue,
  handleRowClick,
  onTableScroll,
  customColumnStyle,
  customHeaderColumnStyle,
  customContainerStyles,
  emptyState,
  enableStickyHeader
}: TableProps) => {
  const [order, setOrder] = useState<Order>(customOrderValue || 'desc');
  const [orderBy, setOrderBy] = useState(
    customSortValue || tableHeaders[0]?.id
  );
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PaginationValues[0] || 0);
  const [maxHeight, setMaxHeight] = useState<number | undefined>();
  const rowHovered = useRef<number | undefined>();
  const tableXScrolled = useRef<boolean>(false);
  const prevScrollY = useRef<number>(0);

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as string);
    setPage(0);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tableData.map(item => item.id as string);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onScroll = (event: any) => {
    if (enableStickyHeader) {
      if (event.target.scrollLeft === 0) tableXScrolled.current = false;
      else tableXScrolled.current = true;

      const currentScrollY = event.target.scrollTop;
      if (currentScrollY > prevScrollY.current) {
        // Scrolling down
        window.scrollTo({
          top: window.innerHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
      // Update the previous scroll position
      prevScrollY.current = currentScrollY;
    }
    onTableScroll?.(event);
  };

  useEffect(() => {
    if (enableStickyHeader) {
      const screenHeight = window.innerHeight;
      setMaxHeight(screenHeight - 180);
    }
  }, []);

  useEffect(() => {
    setPage(0);
    if (customSortValue) setOrderBy(customSortValue);
    if (customOrderValue) setOrder(customOrderValue);
  }, [tableData]);

  const renderTableHead = () => {
    const createSortHandler =
      (property: string) => (event: MouseEvent<unknown>) => {
        handleRequestSort(event, property);
      };

    const rowCount = tableData.length;
    const numSelected = selected.length;

    return (
      <TableHead>
        <TableRow>
          {!hideCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
          )}
          {tableHeaders.map((headerCell, index) => (
            <TableCell
              key={headerCell.id}
              align={'left'}
              padding={headerCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headerCell.id ? order : false}
              style={{
                width: headerCell.width ? headerCell.width : 'auto'
              }}
              className={`${
                enableStickyHeader && index === 0 ? 'sticky-cell-header' : ''
              } ${
                enableStickyHeader && index === 0 && tableXScrolled.current
                  ? 'shadow-right'
                  : ''
              } ${
                enableStickyHeader && rowHovered.current === index
                  ? 'sticky-row-header-hover-highlight'
                  : ''
              } ${customHeaderColumnStyle || ''}`}>
              {!disableSort && !headerCell.disableSort ? (
                <TableSortLabel
                  active={orderBy === headerCell.id}
                  direction={orderBy === headerCell.id ? order : 'asc'}
                  onClick={createSortHandler(headerCell.id)}>
                  {headerCell.label}
                  {orderBy === headerCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{headerCell.label}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
  const visibleRows = useMemo(
    () =>
      orderBy && !disableSort
        ? (tableData as Record<string, string | number | boolean>[])
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : tableData,
    [order, orderBy, page, rowsPerPage, tableData]
  );

  return (
    <div id="customTable">
      <Paper sx={{ width: '100%', mb: 2 }} elevation={elevation}>
        <>
          <TableContainer
            className={customContainerStyles}
            onScroll={onScroll}
            sx={
              enableStickyHeader
                ? {
                    maxHeight: maxHeight
                  }
                : undefined
            }>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ tableLayout: 'fixed' }}>
              {renderTableHead()}
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={tableHeaders.length} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  (disableSort ? tableData : visibleRows)?.map((row, index) => {
                    const isItemSelected = isSelected(row.id as string);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          !hideCheckbox
                            ? handleClick(row.id as string)
                            : handleRowClick && handleRowClick(row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id as string}
                        selected={isItemSelected}
                        sx={{
                          cursor: `${
                            (!hideCheckbox || handleRowClick) && 'pointer'
                          }`
                        }}
                        onMouseEnter={() => {
                          if (enableStickyHeader) rowHovered.current = index;
                        }}
                        onMouseLeave={() => {
                          if (enableStickyHeader) {
                            rowHovered.current = undefined;
                          }
                        }}
                        className="font-normal">
                        {!hideCheckbox && (
                          <TableCell
                            padding="checkbox"
                            className={customColumnStyle}>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>
                        )}
                        {tableHeaders.map((header, idx) => (
                          <TableCell
                            className={`${
                              enableStickyHeader && idx === 0
                                ? 'sticky-cell'
                                : ''
                            } ${
                              enableStickyHeader &&
                              idx === 0 &&
                              tableXScrolled.current
                                ? 'shadow-right'
                                : ''
                            } ${
                              enableStickyHeader && rowHovered.current === index
                                ? 'sticky-row-hover-highlight'
                                : ''
                            } ${customColumnStyle || ''}`}
                            key={
                              String(row[header.id]) +
                              String(index) +
                              String(idx)
                            }
                            align="left"
                            padding={header.disablePadding ? 'none' : 'normal'}
                            style={{
                              whiteSpace: shouldWrapContent
                                ? 'normal'
                                : 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}>
                            {tableData.length
                              ? processTableData(row)[header.id]
                              : {}}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 69.5 * emptyRows
                    }}>
                    <TableCell colSpan={tableHeaders.length + 1} />
                  </TableRow>
                )} */}
                {emptyState && !tableData?.length && (
                  <TableRow>
                    <TableCell colSpan={tableHeaders.length}>
                      {emptyState}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!disablePagination && (
            <TablePagination
              rowsPerPageOptions={PaginationValues}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, pageNum) => handleChangePage(pageNum)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      </Paper>
    </div>
  );
};

export default CustomTable;
