/**
 * Local Storage Service Functions
 * Functions to handle local storage
 *
 * @exposes setLocalData: function to set item in local storage
 * @exposes getLocalData: function to get item from local storage
 * @exposes removeLocalData: function to remove an item from local storage
 * @exposes clearAllLocalData: function to clear all local storage data
 */

const setLocalData = (name: string, data: string | Record<string, unknown>) => {
  localStorage.setItem(name, JSON.stringify(data));
};

const getLocalData = (name: string) =>
  JSON.parse(localStorage.getItem(name) || 'null');

const removeLocalData = (name: string) => {
  localStorage.removeItem(name);
};

const clearAllLocalData = (exception: string[]) => {
  const exceptionItem: Record<string, string>[] = [];
  exception.map(item => {
    exceptionItem.push({ key: item, value: getLocalData(item) });
  });
  localStorage.clear();
  if (exceptionItem.length) {
    exceptionItem.forEach(item => {
      setLocalData(item.key as string, item.value as string);
    });
  }
};

export { setLocalData, getLocalData, removeLocalData, clearAllLocalData };
