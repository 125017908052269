import { createSlice } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  search: '',
  offset: 0,
  limit: 25,
  order: 'desc',
  sortBy: 'submittedOn'
} as ReducerStateType;

export const verifyUserListing = createSlice({
  name: 'verifyUserListing',
  initialState,
  reducers: {
    resetUserListingValues: state => ({
      ...state,
      search: '',
      offset: 0,
      limit: 25,
      order: 'desc',
      sortBy: 'submittedOn'
    }),
    updateUserListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetUserListingValues, updateUserListingValues } =
  verifyUserListing.actions;

export default verifyUserListing.reducer;
