import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  downloadAifReportState: boolean | 'loading';
} = {
  downloadAifReportState: false
};

export const globalApiSupportSlice = createSlice({
  name: 'globalApiSupport',
  initialState,
  reducers: {
    updateDownloadAifReportState: (state, { payload }) => {
      state.downloadAifReportState = payload;
    }
  }
});

export const { updateDownloadAifReportState } = globalApiSupportSlice.actions;

export default globalApiSupportSlice.reducer;
