import React, { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>;

/**
 * Hook to capture outside click of a component
 * @params ref: a ref object to the component to which outside click is to be captured
 * @params handler: function that gets invoked on outside click of the ref component
 */

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
