import React, { FC } from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import { CustomDropDownPropsType } from './types';

const CustomDropDown: FC<CustomDropDownPropsType> = ({
  placeHolder,
  dropDownValue,
  onSelectItem,
  properties
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          '&.Mui-focused .MuiInputLabel-root': {
            borderColor: '#00000060',
            color: '#00000060'
          }
        }}>
        {placeHolder}
      </InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dropDownValue}
        label={placeHolder}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00000060',
            color: '#00000060'
          },
          '&.Mui-focused .MuiOutlinedInput-root': {
            color: '#00000060'
          }
        }}
        onChange={onSelectItem}>
        {properties.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            disabled={item.disabled || false}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropDown;
