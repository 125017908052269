import React, { useEffect, useState, memo } from 'react';
import { TextField, useMediaQuery } from '@mui/material';
import { Clear } from '@mui/icons-material';

interface SearchBarProps {
  onSearch: (query: string) => void;
  placeholder: string;
  label?: string;
  value?: string;
  defaultValue?: string;
  isTypePersist?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  placeholder,
  label,
  value = '',
  defaultValue,
  isTypePersist
}) => {
  const [searchQuery, setSearchQuery] = useState(defaultValue || value);

  const isLargeScreen = useMediaQuery('(min-width:1281px)');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isTypePersist) setSearchQuery(event.target.value);
    onSearch(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(isTypePersist ? value : searchQuery);
    }
  };

  const handleClearClick = () => {
    if (!isTypePersist) setSearchQuery('');
    onSearch('');
  };

  useEffect(() => {
    isTypePersist ? onSearch(value) : setSearchQuery(value);
  }, [value]);

  useEffect(() => {
    const initialValue = defaultValue || value;
    if (initialValue)
      isTypePersist ? onSearch(initialValue) : setSearchQuery(initialValue);
  }, []);

  return (
    <div className="w-full">
      <TextField
        fullWidth
        size={isLargeScreen ? 'medium' : 'small'}
        id="outlined-basic"
        variant="outlined"
        label={label || placeholder}
        placeholder={placeholder}
        value={isTypePersist ? value : searchQuery}
        autoComplete="off"
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {(isTypePersist ? value : searchQuery) && (
                <Clear
                  sx={{
                    color: 'action.active',
                    cursor: 'pointer'
                  }}
                  onClick={handleClearClick}
                />
              )}
            </React.Fragment>
          )
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#00000060'
            }
          },
          '&.MuiFormHelperText-root': {
            '&.Mui-focused fieldset': {
              color: '#00000060'
            }
          }
        }}
      />
    </div>
  );
};

export default memo(SearchBar);
