export enum IsLoggedInValues {
  IS_LOGGED_IN = 'isUserLoggedIn',
  LOGGED_IN = 'true',
  LOGGED_OUT = 'false'
}

export enum AuthorizationValues {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken'
}
