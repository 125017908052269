import React, { memo, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from '@store/store';
import SearchBar from '@components/search-bar/SearchBar';

interface SearchBarProps {
  placeholder: string;
  label?: string;
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchFn?: ActionCreatorWithPayload<any, string>;
  onSearch?: (query: string) => void;
  resetLimitAndOffset?: boolean;
}

const PersistingSearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  label,
  defaultValue,
  dispatchFn,
  onSearch,
  resetLimitAndOffset = false
}) => {
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState(defaultValue);

  const [debouncedSearchTerm] = useDebounce(searchQuery, 300);

  const setSearchText = (searchTerm: string) => {
    setSearchQuery(searchTerm);
    onSearch?.(searchTerm);
  };

  useEffect(() => {
    if (dispatchFn)
      dispatch(
        dispatchFn({
          search: debouncedSearchTerm,
          ...(resetLimitAndOffset ? { offset: 0, limit: 25 } : {})
        })
      );
  }, [debouncedSearchTerm]);

  return (
    <SearchBar
      onSearch={setSearchText}
      placeholder={placeholder}
      label={label}
      value={searchQuery}
      defaultValue={defaultValue}
      isTypePersist
    />
  );
};

export default memo(PersistingSearchBar);
