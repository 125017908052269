import restApi from '@services/api';

import {
  GenerateOtpResponse,
  GenerateOtpRequest,
  ValidateOtpResponse,
  ValidateOtpRequest,
  GetUserProfileResponse
} from './types';

const AuthApis = restApi.injectEndpoints({
  endpoints: builder => ({
    generateOtp: builder.mutation<GenerateOtpResponse, GenerateOtpRequest>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/authentication/otp/email/generate`,
        method: 'POST',
        body: payload
      })
    }),
    validateOtp: builder.mutation<ValidateOtpResponse, ValidateOtpRequest>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION_V2}/authentication/otp/email/validate`,
        method: 'POST',
        body: payload
      })
    }),
    getUserProfile: builder.query<GetUserProfileResponse, void>({
      query: () => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/user-profile`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGenerateOtpMutation,
  useValidateOtpMutation,
  useGetUserProfileQuery
} = AuthApis;
