import { DocumentNode } from 'graphql';

export type ExtraOptions = {
  showNotifier?: boolean;
  failure?: string;
  success?: string;
  showCustomMessage?: boolean;
  successNotifierType?: string;
  failureNotifierType?: string;
};

export type GraphQLArgs = {
  document: string | DocumentNode;
};

export type ErrorType = {
  message: string;
  name: string;
  stack: string;
};

export enum RequestCredentials {
  INCLUDE = 'include',
  SAME_ORIGIN = 'same-origin',
  OMIT = 'omit'
}
