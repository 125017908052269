import React, { useState, useEffect, useRef, FC } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { EllipsisTooltipTextProps } from './types';

const EllipsisTooltipText: FC<EllipsisTooltipTextProps> = ({
  text,
  className
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const isOverflow =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsOverflowing(isOverflow);
    }
  }, [text]);

  return (
    <div>
      {isOverflowing ? (
        <Tooltip title={text} enterDelay={500} arrow>
          <div
            ref={textRef}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {text}
          </div>
        </Tooltip>
      ) : (
        <div
          ref={textRef}
          className={className}
          style={{ whiteSpace: 'nowrap' }}>
          {text}
        </div>
      )}
    </div>
  );
};

export default EllipsisTooltipText;
