import { createSlice } from '@reduxjs/toolkit';

type BreadCrumbChangeData = {
  label: string;
  newLabel: string;
  handleClick?: () => void;
};

const initialState: {
  breadCrumbData?: BreadCrumbChangeData;
} = {};

export const breadCrumbs = createSlice({
  name: 'breadCrumbs',
  initialState,
  reducers: {
    updateBreadCrumb: (state, { payload }) => {
      state.breadCrumbData = payload;
    }
  }
});

export const { updateBreadCrumb } = breadCrumbs.actions;

export default breadCrumbs.reducer;
