import React, { FC } from 'react';
import TextField from '@mui/material/TextField';

import { CustomTextBoxProps } from './types';

const CustomTextBox: FC<CustomTextBoxProps> = ({
  placeHolder,
  onChange,
  inputValue,
  error,
  autoComplete,
  textBoxVariant,
  fullWidth,
  isDisabled,
  errorMessage,
  type,
  onEnterKeyPress
}) => {
  return (
    <div className="w-full">
      <TextField
        size="medium"
        id="outlined-basic"
        label={placeHolder}
        variant={textBoxVariant ?? 'outlined'}
        onChange={onChange}
        type={type}
        inputMode="none"
        value={inputValue}
        error={error}
        autoComplete={autoComplete}
        fullWidth={fullWidth ?? false}
        disabled={isDisabled}
        onKeyDown={onEnterKeyPress}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#00000060'
            }
          },
          '&.MuiFormHelperText-root': {
            '&.Mui-focused fieldset': {
              color: '#00000060'
            }
          }
        }}
      />
      {error && !!errorMessage && (
        <p className="mt-[4px] text-xs text-red">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomTextBox;
