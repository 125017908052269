import { createSlice } from '@reduxjs/toolkit';

import { Order } from '@components/table/types';
import { OpportunityType } from '@modules/companies/create-company/constants';

const initialState: {
  search: string;
  companyId: string;
  sort: string;
  order: Order;
  opportunityType: OpportunityType;
} = {
  search: '',
  companyId: '',
  sort: 'start_time',
  order: 'desc',
  opportunityType: OpportunityType.Waitlist
};

export const opportunityListingSlice = createSlice({
  name: 'opportunityListing',
  initialState,
  reducers: {
    resetOpportunityListingValues: state => ({
      ...state,
      search: '',
      companyId: '',
      sort: 'start_time',
      order: 'desc',
      opportunityType: OpportunityType.Wishlist
    }),
    updateOpportunityListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetOpportunityListingValues, updateOpportunityListingValues } =
  opportunityListingSlice.actions;

export default opportunityListingSlice.reducer;
