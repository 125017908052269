import React, { RefObject } from 'react';

import Tooltip from '@mui/material/Tooltip';

import './styles.css';

interface OverflowTooltipTextBoxProps {
  content: string;
  textStyle?: string;
}

const OverflowTooltipTextBox: React.FC<OverflowTooltipTextBoxProps> = ({
  content,
  textStyle
}) => {
  const contentRef: RefObject<HTMLDivElement> = React.createRef();

  const handleMouseOver = () => {
    if (
      contentRef.current &&
      contentRef.current.offsetWidth < contentRef.current.scrollWidth
    ) {
      if (contentRef.current.classList) {
        contentRef.current.classList.add('overflowed');
      }
    }
  };

  const handleMouseOut = () => {
    if (contentRef.current && contentRef.current.classList) {
      contentRef.current.classList.remove('overflowed');
    }
  };

  return (
    <div
      className="overflow-tooltip-container"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={contentRef}>
      <Tooltip
        title={content}
        arrow
        placement="top"
        enterDelay={500}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '12px'
            }
          }
        }}>
        <div className={textStyle}>{content}</div>
      </Tooltip>
    </div>
  );
};

export default OverflowTooltipTextBox;
