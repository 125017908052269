import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'createdOn',
  clearPage: true
} as ReducerStateType;

export const accessCodeListingSlice = createSlice({
  name: 'accessCodeListing',
  initialState,
  reducers: {
    resetValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'createdOn'
    }),
    updateAccessCodeListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    updateAccessCodeListingClearPage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      clearPage: payload
    })
  }
});

export const {
  resetValues,
  updateAccessCodeListingValues,
  updateAccessCodeListingClearPage
} = accessCodeListingSlice.actions;

export default accessCodeListingSlice.reducer;
