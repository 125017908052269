import React, { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { colors } from '@constants/colors';

import { DualButtonPropsType } from './types';

const DualButton: FC<DualButtonPropsType> = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  onChangePrimaryButton,
  onChangeSecondaryButton,
  primaryButtonVariant,
  secondaryButtonVariant,
  lowerCaseLabel,
  primaryEndIcon,
  primaryStartIcon,
  secondaryEndIcon,
  secondaryStartIcon,
  primaryButtonCustomStyle = {
    backgroundColor: colors.primaryBlue,
    color: colors.white
  },
  secondaryButtonCustomStyle = {
    backgroundColor: colors.gray88,
    color: colors.black
  },
  isSecondaryDisabled = false,
  isPrimaryDisabled = false,
  primaryButtonType = 'button',
  isPrimaryButtonLoading = false,
  customDisabledStyle,
  isSecondaryButtonLoading
}) => {
  const disabledStyle = {
    backgroundColor: colors.gray88,
    color: colors.gray,
    ...customDisabledStyle
  };

  return (
    <div className="flex flex-row gap-5">
      {secondaryButtonLabel && (
        <Button
          disabled={isSecondaryDisabled}
          style={
            isSecondaryDisabled ? disabledStyle : secondaryButtonCustomStyle
          }
          variant={secondaryButtonVariant ?? 'contained'}
          onClick={onChangeSecondaryButton}
          sx={{
            textTransform: lowerCaseLabel ?? false ? 'capitalize' : 'uppercase'
          }}
          endIcon={secondaryEndIcon}
          startIcon={secondaryStartIcon}>
          {isSecondaryButtonLoading ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (
            secondaryButtonLabel
          )}
        </Button>
      )}

      {primaryButtonLabel && (
        <Button
          type={primaryButtonType}
          disabled={isPrimaryDisabled || isPrimaryButtonLoading}
          style={isPrimaryDisabled ? disabledStyle : primaryButtonCustomStyle}
          variant={primaryButtonVariant ?? 'contained'}
          onClick={onChangePrimaryButton}
          sx={{
            textTransform: lowerCaseLabel ?? false ? 'capitalize' : 'uppercase'
          }}
          endIcon={primaryEndIcon}
          startIcon={primaryStartIcon}>
          {isPrimaryButtonLoading ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (
            primaryButtonLabel
          )}
        </Button>
      )}
    </div>
  );
};

export default DualButton;
