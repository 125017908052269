import { ApiTags } from '@constants/common';
import { OpportunityType } from '@modules/companies/create-company/constants';
import { Order } from '@components/table/types';
import restApi from '@services/api';

import {
  ChangeDealStateRequestType,
  ChangeDealStateResponseType,
  CloseDealResposneType,
  GetDealsResult,
  GetDealsResponse,
  DownloadDealistJobIdRes,
  GetDeallistDownloadUrlRes,
  DealsAnalyticsDetails,
  DealsAnalyticsDetailsResponse
} from './types';
import { tableHeaderMapper } from './constants';
import { DownloadReportResponseType } from '../deal-analytics/types';

const GetDealsApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getDeals: builder.query<
      GetDealsResult,
      {
        offset?: number;
        limit?: number;
        order?: Order;
        sortBy?: string;
        searchTerm?: string;
        companyId?: string;
        opportunityType?: OpportunityType;
      }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal?${
          payload.searchTerm ? `&search=${payload.searchTerm}` : ''
        }${payload.companyId ? `&company_id=${payload.companyId}` : ''}${
          payload.opportunityType
            ? `&opportunity_type=${payload.opportunityType}`
            : ''
        }&limit=${payload.limit}&offset=${payload.offset}&sort=${
          tableHeaderMapper[payload.sortBy as keyof typeof tableHeaderMapper] ??
          'start_time'
        }&order=${payload.order}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.dealList],
      transformResponse: (response: GetDealsResponse) => response.result
    }),
    changeDealState: builder.mutation<
      ChangeDealStateResponseType,
      ChangeDealStateRequestType
    >({
      query: payload => ({
        // eslint-disable-next-line max-len
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/status`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: result =>
        result?.result
          ? [
              ApiTags.dealList,
              ApiTags.deal,
              ApiTags.earlyAccess,
              ApiTags.wishlist
            ]
          : [],
      transformResponse: (response: ChangeDealStateResponseType) => response
    }),
    closeDeal: builder.mutation<CloseDealResposneType, { dealid: string }>({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/close/${payload.dealid}`,
        method: 'PUT'
      }),
      invalidatesTags: [
        ApiTags.dealList,
        ApiTags.earlyAccess,
        ApiTags.wishlist,
        ApiTags.deal
      ]
    }),
    getDownloadDealsJobId: builder.mutation<
      DownloadDealistJobIdRes,
      {
        offset?: number;
        limit?: number;
        order?: Order;
        sortBy?: string;
        searchTerm?: string;
        companyId?: string;
        opportunityType?: OpportunityType;
      }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/export?${
          payload.searchTerm ? `&search=${payload.searchTerm}` : ''
        }${payload.companyId ? `&company_id=${payload.companyId}` : ''}${
          payload.opportunityType
            ? `&opportunity_type=${payload.opportunityType}`
            : ''
        }&sort=${
          tableHeaderMapper[payload.sortBy as keyof typeof tableHeaderMapper] ??
          'start_time'
        }&order=${payload.order}`,
        method: 'POST',
        body: {
          format: 'excel'
        }
      }),
      transformResponse: (response: DownloadDealistJobIdRes) => response
    }),
    getDealsDowndloadUrl: builder.query<
      GetDeallistDownloadUrlRes,
      {
        jobId: string;
      }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/export/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: GetDeallistDownloadUrlRes) => response
    }),
    getDealsAnalytics: builder.query<DealsAnalyticsDetails, void>({
      query: () => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/deal/analytics `,
        method: 'GET'
      }),
      transformResponse: (response: DealsAnalyticsDetailsResponse) =>
        response.result
    }),
    getAifMasterDbReport: builder.query<DownloadReportResponseType, void>({
      query: () => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/aif/79472825-48b9-4583-8c2f-776c5dc138fb/master-db-report`,
        method: 'POST'
      }),
      transformResponse: (response: DownloadReportResponseType) => response
    })
  })
});

export const {
  useGetDealsQuery,
  useLazyGetDealsQuery,
  useChangeDealStateMutation,
  useCloseDealMutation,
  useGetDownloadDealsJobIdMutation,
  useLazyGetDealsDowndloadUrlQuery,
  useGetDealsAnalyticsQuery,
  useLazyGetAifMasterDbReportQuery
} = GetDealsApi;
