import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Switch } from '@mui/material';

import { colors } from '@constants/colors';

import { CustomSwitchPropsType } from './types';

const switchCustomStyles = {
  '& .MuiSwitch-switchBase': {
    color: colors.alabaster,
    '& + .MuiSwitch-track': {
      opacity: 0.3,
      backgroundColor: colors.black
    },
    '&.Mui-checked': {
      color: colors.primaryBlue,
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: colors.primaryBlue
      }
    }
  }
};

const CustomSwitch: FC<CustomSwitchPropsType> = ({
  label,
  checked,
  handleChange = () => {},
  control,
  name,
  defaultValue,
  isDisabled = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...restField } }) => (
        <FormControlLabel
          control={
            <Switch
              disabled={isDisabled}
              {...restField}
              checked={checked}
              defaultChecked={defaultValue}
              onChange={e => {
                handleChange(e);
                onChange(e);
              }}
              sx={switchCustomStyles}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default CustomSwitch;
