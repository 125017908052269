import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ToastTypes } from '@type/toast';
import { translate } from '@utils/locale';
import { RootState } from '@store/reducers';
import { useAppDispatch } from '@store/store';
import { handleDownloadFile } from '@utils/common';
import { showGenericErrorToast } from '@utils/error';
import { API_RESPONSE_STATUS } from '@constants/common';
import { updateDownloadAifReportState } from '@reducers/globalApiSupport';
import { useLazyGetAifMasterDbReportQuery } from '@modules/deals/deals-list/api';
import { useLazyGenerateReportPollQuery } from '@modules/deals/deal-analytics/api';
import displayToast from '@components/toast/customToast';

const useDownloadAifMasterReport = () => {
  const dispatch = useAppDispatch();
  const { downloadAifReportState } = useSelector(
    (state: RootState) => state.rootReducer.globalApiSupport
  );

  const [
    downloadAifMasterDbReport,
    {
      data: downloadAifMasterDbReportData,
      error: downloadAifMasterDbReportError
    }
  ] = useLazyGetAifMasterDbReportQuery();

  const [pollingInterval, setPollingInterval] = useState<number>(30000);
  const [jobID, setJobId] = useState('');

  const [
    generateReportPoll,
    { data: pollStatus, isLoading, error: pollStatusError }
  ] = useLazyGenerateReportPollQuery();

  const onGeneratePoll = (jobId: string) => {
    generateReportPoll({
      jobId: jobId
    });
  };

  const updateReportState = (value: boolean | 'loading') => {
    dispatch(updateDownloadAifReportState(value));
  };

  useEffect(() => {
    if (downloadAifReportState === true) {
      downloadAifMasterDbReport();
      updateReportState('loading');
      setPollingInterval(30000);
    }
  }, [downloadAifReportState]);

  useEffect(() => {
    if (downloadAifReportState !== 'loading' && isLoading) {
      updateReportState('loading');
    }
  }, [isLoading]);

  useEffect(() => {
    if (downloadAifMasterDbReportData?.status === API_RESPONSE_STATUS.OK) {
      onGeneratePoll(downloadAifMasterDbReportData.result.job_id);
      setJobId(downloadAifMasterDbReportData.result.job_id);
      displayToast(
        ToastTypes.WARNING,
        translate('dealListingPage.aifReportDownloadMessage'),
        '',
        toast.POSITION.TOP_CENTER,
        true,
        'aif-report-download-toast'
      );
    } else {
      updateReportState(false);
    }
  }, [downloadAifMasterDbReportData]);

  useEffect(() => {
    if (downloadAifMasterDbReportError || pollStatusError) {
      updateReportState(false);
      setPollingInterval(0);
      setJobId('');
      displayToast(ToastTypes.ERROR, translate('errorFallbackMessage'));
    }
  }, [downloadAifMasterDbReportError, pollStatusError]);

  useEffect(() => {
    if (pollStatus && downloadAifMasterDbReportData) {
      if (pollStatus?.result?.status === 'success') {
        toast.dismiss('aif-report-download-toast');
        displayToast(ToastTypes.SUCCESS, 'Report generated successfully');
        handleDownloadFile(pollStatus.result.signed_url);
        updateReportState(false);
        setPollingInterval(0);
        setJobId('');
      } else if (pollStatus?.result.status !== 'processing') {
        showGenericErrorToast(pollStatus.error);
        //TODO: show proper error messages frm BE
        updateReportState(false);
        setPollingInterval(0);
        setJobId('');
      }
    }
  }, [pollStatus]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (jobID && pollingInterval) {
      interval = setInterval(() => onGeneratePoll(jobID), pollingInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [jobID, pollingInterval]);

  return {};
};

export default useDownloadAifMasterReport;
