import { ReducerStateType } from '@modules/users/user-listing/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'profiling_status_last_updated',
  clearPage: true,
  totalCount: 0
} as ReducerStateType;

export const screeningWaitlistedListSlice = createSlice({
  name: 'screeningWaitlistListing',
  initialState,
  reducers: {
    resetScreeningWaitlistedListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'profiling_status_last_updated',
      totalCount: 0
    }),
    updateScreeningWaitlistedListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    updateScreeningListingClearPage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      clearPage: payload
    })
  }
});

export const {
  resetScreeningWaitlistedListingValues,
  updateScreeningWaitlistedListingValues,
  updateScreeningListingClearPage
} = screeningWaitlistedListSlice.actions;

export default screeningWaitlistedListSlice.reducer;
