/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Paper } from '@mui/material';

import { Breadcrumbs } from '@components';
import { BreadcrumbsData } from '@components/breadcrumbs/types';
import { RootState } from '@store/reducers';

interface TopBarProps {
  breadcrumbsData: BreadcrumbsData[];
}

const TopBar: React.FC<TopBarProps> = ({ breadcrumbsData }) => {
  const breadCrumbChangeData = useSelector(
    (state: RootState) => state.rootReducer.breadCrumb.breadCrumbData
  );

  const [breadCrumbValues, setBreadCrumbs] = useState(breadcrumbsData);

  useEffect(() => {
    if (breadCrumbChangeData?.newLabel) {
      const updatedBreadCrumbs = breadcrumbsData.map(item => {
        if (item.label === breadCrumbChangeData.label) {
          return {
            ...item,
            label: breadCrumbChangeData.newLabel,
            handleClick: breadCrumbChangeData.handleClick
              ? breadCrumbChangeData.handleClick
              : item.handleClick
          };
        }
        return item;
      });
      setBreadCrumbs(updatedBreadCrumbs);
    } else {
      setBreadCrumbs(breadcrumbsData);
    }
  }, [breadCrumbChangeData, breadcrumbsData]);

  return (
    <Paper elevation={1} style={{ backgroundColor: 'white' }}>
      <AppBar
        position="fixed"
        style={{
          height: 64,
          backgroundColor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid #E9E9E9'
        }}>
        <Toolbar
          style={{
            display: 'flex',
            width: '100%',
            padding: '0'
          }}>
          {/* eslint-disable-next-line tailwindcss/classnames-order */}
          <div className="flex justify-start items-center py-6 pl-8  w-60 h-full text-[22px] font-bold text-center text-white border-r-2 border-white bg-balticSea">
            infinyte.club
          </div>
          <div className="flex justify-between items-center py-6 pr-2 pl-5 w-[calc(100%-240px)]">
            <Breadcrumbs data={breadCrumbValues} />
          </div>
        </Toolbar>
      </AppBar>
    </Paper>
  );
};

export default TopBar;
