const routesPath = {
  ALL: '*',
  DEFAULT: '/',
  LOGIN: '/login',
  DEALS: '/deals',
  COMPANY: '/companies',
  CREATE_DEAL: '/deals/create',
  CREATE_COMPANY: '/companies/create',
  EDIT_DEAL: '/deals/edit',
  SYNDICATE: '/syndicate',
  CREATE_SYNDICATE: '/syndicate/create',
  DEAL_INVITE: '/deals/invite',
  SYNDICATE_INVITE: '/syndicate/invite',
  EDIT_SYNDICATE: '/syndicate/edit',
  USER: '/users',
  VIEW_USER: '/users/details',
  APP_INVITE: '/users/app-invite',
  VERIFY_USER: '/verify',
  UPDATE_VERIFY: '/verify/update',
  DEAL_ANALYTICS: '/deals/analytics',
  OPPORTUNITIES: '/opportunities',
  CREATE_WISHLIST: '/opportunities/wishlist/create',
  EDIT_WISHLIST: '/opportunities/wishlist/edit',
  VIEW_WISHLIST: '/opportunities/wishlist/view',
  CREATE_EARLY_ACCESS: '/opportunities/early-access/create',
  EDIT_EARLY_ACCESS: '/opportunities/early-access/edit',
  VIEW_EARLY_ACCESS: '/opportunities/early-access/view',
  SCREENING: '/screening'
};

export default routesPath;
