import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';

const TextFieldWithPattern = ({
  label,
  placeholder,
  pattern,
  replaceCharacters,
  onChange,
  defaultValue,
  inputValidation,
  maxLength,
  isError,
  field,
  isDisabled,
  errors,
  description
}: {
  label: string;
  placeholder?: string;
  pattern: string;
  replaceCharacters: string[];
  onChange: (value: string) => void;
  defaultValue?: string;
  inputValidation?: (val: string) => boolean;
  maxLength?: number;
  isError?: boolean;
  field?: ControllerRenderProps<FieldValues, string>;
  isDisabled?: boolean;
  errors?: string;
  description?: string;
}) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const [placeHolder, setPlaceholder] = useState(pattern);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Gets replace positions from the pattern
  let replacePositions: number[] = [];

  for (let index = 0; index < pattern.length; index++) {
    if (replaceCharacters.includes(pattern[index] ?? ''))
      replacePositions.push(index);
  }

  // Extracts string for example "23-45-12" in "XX-XX-XX" gives 234512
  const getStringValue = (input: string) => {
    const inputString = replacePositions?.reduce((acc, val) => {
      if (input[val]) return acc + input[val];
      return acc;
    }, '');
    return inputString;
  };

  const getNewStringAndPattern = (
    input: string,
    isBackspaceTriggered?: boolean
  ) => {
    if (!input) return { newInputString: '', newPatternString: pattern };
    const isValid = inputValidation ? inputValidation(input) : true;
    if (!isValid)
      return { newInputString: inputValue, newPatternString: placeHolder };

    const isBackSpace =
      isBackspaceTriggered || input.length < getStringValue(inputValue).length;

    const inputValuesArray = input.split('');
    const newPattern = pattern.split('');
    let inputValuesArrayIterator = 0;
    for (const index of replacePositions) {
      newPattern[index] =
        inputValuesArray[inputValuesArrayIterator] ?? newPattern[index] ?? '';
      inputValuesArrayIterator = inputValuesArrayIterator + 1;
    }
    const newPatternString = newPattern.join('');
    let newInputString = newPatternString.substring(
      0,
      replacePositions[input.length]
    );

    if (isBackSpace && !replacePositions.includes(newInputString.length - 1)) {
      newInputString = newInputString.substring(
        0,
        (replacePositions[input.length - 1] ?? 0) + 1
      );
    }
    return { newInputString, newPatternString };
  };

  const onInputChange = (input: string, isBackspaceTriggered?: boolean) => {
    const { newInputString, newPatternString } = getNewStringAndPattern(
      input,
      isBackspaceTriggered
    );
    setInputValue(newInputString);
    setPlaceholder(newPatternString);
    onChange(getStringValue(newInputString));
  };

  const onTextFieldInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newInputValue = event.target.value.trimEnd();
    if (inputValue) onInputChange(getStringValue(newInputValue));
    else onInputChange(newInputValue); // because first type will be not following the pattern so string itself
  };

  const restrictCursorMovement = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
  ) => {
    // Delay the retrieval of selectionStart and selectionEnd by a small amount
    setTimeout(() => {
      if (inputRef.current) {
        const { selectionStart, selectionEnd, value } = inputRef.current;
        // Check if the cursor is not at the end or if there's a selection
        if (
          selectionStart !== value.length ||
          selectionStart !== selectionEnd
        ) {
          event.preventDefault(); // Prevent the default behavior
          inputRef.current.setSelectionRange(value.length, value.length); // sets cursor to end of string
        }
      }
    }, 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      if (event.key === 'Backspace') {
        onInputChange(getStringValue(value), true);
      }
    }
    restrictCursorMovement(event);
  };

  useEffect(() => {
    if (defaultValue) {
      onInputChange(defaultValue);
    } else {
      setInputValue('');
      setPlaceholder(pattern);
    }
  }, [defaultValue, pattern]);

  return (
    <div className="relative">
      <TextField
        {...field}
        label={label || placeholder}
        placeholder={isFocused ? undefined : placeholder}
        InputLabelProps={{
          shrink: true
        }}
        className="w-full"
        inputProps={{
          style: {
            height: '100%'
          },
          maxLength: maxLength || 250
        }}
        onChange={onTextFieldInputChange}
        onKeyDown={handleKeyDown}
        onMouseDown={restrictCursorMovement}
        value={inputValue}
        inputRef={inputRef}
        error={isError}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={isDisabled}
        helperText={errors ? errors : description}
      />
      {isFocused && (
        <Typography
          color="textSecondary"
          style={{
            position: 'absolute',
            top: 15,
            left: 14,
            pointerEvents: 'none',
            zIndex: 0
          }}>
          {placeHolder}
        </Typography>
      )}
    </div>
  );
};

export default TextFieldWithPattern;
