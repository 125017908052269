// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

const replaceEmptyStrings = (
  obj: AnyObject | undefined
): AnyObject | undefined => {
  if (obj === undefined) {
    return undefined;
  }

  const newObj: AnyObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (typeof value === 'string' && value === '') {
      newObj[key] = null;
    } else if (value instanceof Date) {
      newObj[key] = new Date(value);
    } else if (value !== null && typeof value === 'object') {
      newObj[key] = replaceEmptyStrings(value);
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
};

export { replaceEmptyStrings };
