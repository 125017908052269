import React, { FC, SyntheticEvent } from 'react';
import { Tab, Tabs } from '@mui/material';

import { TabPropType } from './type';

const CustomTab: FC<TabPropType> = ({ options, handleTabClick, value }) => {
  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    index: number
  ) => {
    event.stopPropagation();
    handleTabClick(options[index]?.id ?? '');
  };

  const selectedTabIndex = options.findIndex(item => item.id === value);

  return (
    <div>
      <Tabs
        value={selectedTabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary">
        {options.map((option, index) => (
          <Tab
            sx={{
              padding: '10px',
              '@media (max-width: 1280px)': {
                padding: '4px',
                fontSize: '13px'
              }
            }}
            key={index}
            label={option.label}
            className="hover:bg-gray-200"
            iconPosition="start"
            icon={
              option.count !== undefined ? (
                <div
                  className={`flex justify-center items-center w-6 h-6 text-xs  rounded-full ${
                    selectedTabIndex === index
                      ? 'bg-primary text-white'
                      : 'bg-white text-gray-500 border border-gray-500'
                  }`}>
                  {option.count}
                </div>
              ) : undefined
            }
            style={{ minHeight: 'auto' }}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default CustomTab;
