import { createSlice } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  companyId: ''
} as ReducerStateType;

export const createDealStates = createSlice({
  name: 'createDealStates',
  initialState,
  reducers: {
    resetCreateDealStateValues: () => ({
      companyId: ''
    }),
    updateCreateDealStateValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetCreateDealStateValues, updateCreateDealStateValues } =
  createDealStates.actions;

export default createDealStates.reducer;
