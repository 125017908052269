import { ReduxError } from '@type/general';
import { ToastTypes } from '@type/toast';
import displayToast from '@components/toast/customToast';
import { ToastPosition } from 'react-toastify';

const errorBoundaryHandler = (
  error: Error,
  info: { componentStack: string }
) => {
  // eslint-disable-next-line no-console
  console.log(error, info);
  // Do something with the error
  // E.g. log to an error logging client here
};

const showGenericErrorToast = (
  reduxError: unknown,
  position?: ToastPosition
) => {
  if (reduxError) {
    const error = reduxError as ReduxError;
    displayToast(
      ToastTypes.ERROR,
      error?.data?.error?.details?.[0] ?? JSON.stringify(error),
      '',
      position
    );
  }
};

export { errorBoundaryHandler, showGenericErrorToast };
