const getNumberFromString = (numberString: string) => {
  const numberArray = numberString.match(/\d/g);
  const number = numberArray && numberArray.join('');
  return number || '0';
};

const getFormattedAmount = (amount: string) => {
  const formattedAmount = amount?.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');

  return formattedAmount;
};

function formatUSDCurrency(amount: number) {
  // Ensure that the input is a valid number
  if (isNaN(amount)) {
    return '';
  }

  // Add commas for thousands separator
  return amount.toLocaleString('en-US', {
    // style: 'currency',
    // currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  });
}

const isDecimal = (number: number) => {
  if (Number.isInteger(number)) {
    return false;
  }
  return true;
};

const formatAmount = (number: number) => {
  if (isNaN(number)) {
    return '';
  }

  if (number >= 10000000) {
    if (isDecimal(number / 10000000)) {
      return `₹${(number / 10000000).toFixed(2)}Cr`;
    }
    return `₹${number / 10000000}Cr`;
  } else if (number >= 100000) {
    if (isDecimal(number / 100000)) {
      return `₹${(number / 100000).toFixed(2)}L`;
    }
    return `₹${number / 100000}L`;
  } else if (number >= 1000) {
    if (isDecimal(number / 1000)) {
      return `₹${(number / 1000).toFixed(2)}K`;
    }
    return `₹${number / 1000}K`;
  }
  return `₹${number.toString()}`;
};

const convertToUSD = (amount: number) => {
  return amount * 0.012;
};

const formatAmountInDollars = (amount: number) => {
  if (isNaN(amount)) {
    return '';
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let amountInDollars = parseFloat(String(amount));
  let suffixIndex = 0;

  while (amountInDollars >= 1000 && suffixIndex < suffixes.length - 1) {
    amountInDollars = amountInDollars / 1000;
    suffixIndex = suffixIndex + 1;
  }

  const formattedAmount = `$${amountInDollars.toFixed(2)}${
    suffixes[suffixIndex]
  }`;

  return formattedAmount;
};

export {
  getFormattedAmount,
  getNumberFromString,
  formatAmount,
  convertToUSD,
  formatAmountInDollars,
  formatUSDCurrency
};
