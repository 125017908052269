import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import RoutesPath from './RoutesPath';

const LoginEmailPage = lazy(() => import('@modules/auth/login'));

const PublicLayout = () => {
  const location = useLocation();

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path={RoutesPath.LOGIN} element={<LoginEmailPage />} />
        <Route
          path={RoutesPath.ALL}
          element={
            <Navigate
              to={RoutesPath.LOGIN}
              state={{ from: location.pathname }}
              replace
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default PublicLayout;
