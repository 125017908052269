import { FC } from 'react';

import { translate } from '@utils/locale';
import CustomButton from '@components/custom-button/CustomButton';

import { FormDualButtonProps } from './types';

const FormDualButton: FC<FormDualButtonProps> = ({
  showCancelOrSaveButton,
  enableEdit,
  onClickCancelButton,
  onClickEditButton,
  isSaveDisabled
}) => {
  return (
    <div className="flex flex-row gap-4">
      {showCancelOrSaveButton && (
        <CustomButton
          variant="outlined"
          fullWidth={false}
          onClick={onClickCancelButton}
          label={'Cancel'}
        />
      )}
      {enableEdit && (
        <CustomButton
          variant="contained"
          fullWidth={false}
          onClick={onClickEditButton}
          isDisabled={showCancelOrSaveButton ? isSaveDisabled : false}
          label={showCancelOrSaveButton ? translate('save') : translate('edit')}
        />
      )}
    </div>
  );
};

export default FormDualButton;
