import React, { FC } from 'react';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { isEmpty } from 'lodash';

import TextFieldWithPattern from '@components/text-field-with-pattern/TextFieldWithPattern';

type RhfTextFieldWithPatternProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  name: string;
  label: string;
  placeholder?: string;
  pattern: string;
  replaceCharacters: string[];
  onInputChange: (value: string) => void;
  defaultValue?: string;
  inputValidation?: (val: string) => boolean;
  maxLength?: number;
  errors?: FieldErrors<FieldValues>;
  wrapperClass?: string;
  isDisabled?: boolean;
  description?: string;
};

const RhfTextFieldWithPattern: FC<
  RhfTextFieldWithPatternProps & TextFieldProps
> = ({
  control,
  name,
  label,
  placeholder,
  pattern,
  replaceCharacters,
  onInputChange,
  defaultValue,
  inputValidation,
  maxLength,
  errors,
  wrapperClass = '',
  isDisabled,
  description
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={`relative ${wrapperClass}`}>
          <TextFieldWithPattern
            {...field}
            label={label}
            placeholder={placeholder}
            pattern={pattern}
            replaceCharacters={replaceCharacters}
            onChange={onInputChange}
            defaultValue={defaultValue}
            inputValidation={inputValidation}
            maxLength={maxLength}
            isError={Boolean(
              !isEmpty(errors) &&
                (errors[name] as { message?: string })?.message
            )}
            isDisabled={isDisabled}
            errors={
              !isEmpty(errors) && errors[name as keyof typeof errors]
                ? (
                    errors[name as keyof typeof errors] as {
                      message?: string;
                    }
                  )?.message
                : undefined
            }
            description={description}
          />
        </div>
      )}
    />
  );
};

export default RhfTextFieldWithPattern;
