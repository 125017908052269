import { FC } from 'react';
import { Skeleton, Typography } from '@mui/material';

const PageLoader: FC = () => {
  return (
    <div className="m-5">
      <div className="flex justify-between">
        <Skeleton
          variant="rectangular"
          width={350}
          height={50}
          className="mb-4"
          animation="wave"
        />
        <div className="flex">
          <Skeleton
            variant="rounded"
            width={84}
            height={42}
            animation="wave"
            className="mr-1"
          />
          <Skeleton
            variant="rounded"
            width={120}
            height={42}
            animation="wave"
          />
        </div>
      </div>
      <div className="flex py-4 mt-1">
        <Skeleton
          variant="rounded"
          width={106}
          height={30}
          animation="wave"
          className="mr-2"
        />
        <Skeleton variant="rounded" width={106} height={30} animation="wave" />
      </div>
      <div className="my-4">
        <Skeleton variant="rounded" width={450} height={56} animation="wave" />
      </div>

      <div className="flex mt-5 w-full">
        {Array.from({ length: 6 }).map(item => (
          <div key={(item + 'header') as string} className="w-1/6">
            <div className="flex items-center p-4  h-[56px] text-left bg-[#f4f4f5]">
              <Typography variant="h6" className="w-2/3">
                {<Skeleton animation="wave" />}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      {Array.from({ length: 12 }).map(val => (
        <div className="flex w-full" key={val as string}>
          {Array.from({ length: 6 }).map(item => (
            <div key={(item + 'data') as string} className="w-1/6">
              <div className="flex items-center p-4  h-[56px] text-left bg-[#fcfcfcf7]">
                <Typography variant="body1" className="w-1/3">
                  {<Skeleton animation="wave" />}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PageLoader;
