import { GenericApiResponse } from '@type/general';
import { ApiTags } from '@constants/common';
import restApi from '@services/api';

import { KycDocListResponse, VerifyUserListingPayload } from './types';
import { tableHeaderMapper } from './constants';

const GetUserVerificationApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getUnverifiedKycDocsList: builder.query<
      KycDocListResponse,
      VerifyUserListingPayload
    >({
      query: ({ limit, offset, search, sort, order }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/users/kyc/doc?status=InReview&limit=${limit}&offset=${offset}${
          search ? `&search=${encodeURIComponent(search)}` : ''
        }&sort=${
          tableHeaderMapper[sort as keyof typeof tableHeaderMapper]
        }&order=${order}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.userVerification],
      transformResponse: (response: GenericApiResponse<KycDocListResponse>) =>
        response.result
    }),
    getVerifiedKycDocsList: builder.query<
      KycDocListResponse,
      VerifyUserListingPayload
    >({
      query: ({ limit, offset, search, sort, order }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/users/kyc/doc?status=Approved&limit=${limit}&offset=${offset}${
          search ? `&search=${encodeURIComponent(search)}` : ''
        }&sort=${
          tableHeaderMapper[sort as keyof typeof tableHeaderMapper]
        }&order=${order}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.userVerification],
      transformResponse: (response: GenericApiResponse<KycDocListResponse>) =>
        response.result
    })
  })
});

export const {
  useLazyGetUnverifiedKycDocsListQuery,
  useLazyGetVerifiedKycDocsListQuery,
  useGetUnverifiedKycDocsListQuery
} = GetUserVerificationApi;
