import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HashRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import { errorBoundaryHandler } from '@utils/error';
import { colors } from '@constants/colors';
import ErrorFallback from '@components/error-fallback/ErrorFallback';
import store from '@store/store';

import { RouteLayout } from './routes';
import './styles/global.css';

const App: FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primaryBlue
      },
      error: {
        main: colors.warningRed
      }
    },
    typography: {
      fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
      pageHeading: {
        fontSize: '34px',
        fontWeight: 400,
        '@media (max-width:1280px)': {
          fontSize: '28px'
        },
        '@media (max-width:1024px)': {
          fontSize: '24px'
        }
      },
      title: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 500
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'IBM Plex Sans, sans-serif'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: colors.primaryBlue
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000ab'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: colors.gray88,
                borderRadius: '5px',
                borderWidth: '1px'
              },
              '&.Mui-focused fieldset': {
                borderColor: colors.primaryBlue,
                borderRadius: '5px',
                borderWidth: '1px'
              }
            }
          }
        }
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            pageHeading: 'div'
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '20px'
          }
        }
      }
    }
  });

  useEffect(() => {
    document.body.classList.add('scrollbar');
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={errorBoundaryHandler}>
          <ToastContainer transition={Slide} />
          <ThemeProvider theme={theme}>
            <RouteLayout />
          </ThemeProvider>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
};

export default App;
