import React, { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

import { translateDynamic } from '@utils/locale';

import { StatusChangePropsType } from './types';

const StatusChangeModal: FC<StatusChangePropsType> = ({
  status,
  selectedStatus,
  onClick,
  isSyndicate,
  isOpportunity
}) => {
  const getLabel = (item: string) => {
    if (isSyndicate) {
      return translateDynamic(`syndicate.changeState.${item}`);
    }
    if (isOpportunity) {
      return translateDynamic(`opportunityChangeState.${item}`);
    }
    return translateDynamic(`changeState.${item}`);
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="mb-2">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group">
            {status.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item}
                  checked={item === selectedStatus}
                  onClick={() => onClick(item)}
                  control={<Radio />}
                  label={getLabel(item)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default StatusChangeModal;
