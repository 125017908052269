import { createSlice } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  search: '',
  offset: 0,
  limit: 25,
  order: 'desc',
  sortBy: 'createdOn'
} as ReducerStateType;

export const companyListing = createSlice({
  name: 'companyListing',
  initialState,
  reducers: {
    resetcompanyListingValues: state => ({
      ...state,
      search: '',
      offset: 0,
      limit: 25,
      order: 'desc',
      sortBy: 'createdOn'
    }),
    updateCompanyListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetcompanyListingValues, updateCompanyListingValues } =
  companyListing.actions;

export default companyListing.reducer;
