/* eslint-disable no-nested-ternary */
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { parse } from 'iso8601-duration';

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const getFormattedDate = (
  date: Date | string | Dayjs,
  dateFormat = 'DD/MM/YYYY'
): string => {
  return dayjs(date)?.format(dateFormat);
};

const getUTCTime = (date: Date) => {
  return dayjs
    .utc(
      new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
      )
    )
    .format();
};

const getUtcEndTime = (date: Date) => {
  return dayjs.utc(getEndTime(date)).format();
};

const getEndTime = (date: Date, timeZone?: string) => {
  const endTime = dayjs(date)
    .tz(timeZone ?? 'Asia/Kolkata', true)
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59);
  return endTime;
};

const getUtcDate = (date: Date, baseTimeZone: string) => {
  return dayjs(date)
    .tz(baseTimeZone, true)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .utc()
    .format();
};

const getDateFromUtc = (utcDate: string, baseTimeZone: string) => {
  return dayjs(utcDate).utc().tz(baseTimeZone);
};

const getTodayWithTimeZone = (baseTimeZone: string) => {
  return dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .tz(baseTimeZone, true);
};

const getDuration = (time: string | undefined) => {
  if (time) {
    const { years, months } = parse(time);
    return `${years ? `${years} ${years > 1 ? 'yrs' : 'yr'}` : ''}
        ${months ? `${months} ${months > 1 ? 'mos' : 'mo'}` : ''}`;
  }
  return '';
};

const getFullDuration = (time: string | undefined) => {
  if (time) {
    const { years, months } = parse(time);
    return `${years ? `${years} ${years > 1 ? 'years' : 'year'}` : ''}${
      months ? ` ${months} ${months > 1 ? 'months' : 'month'}` : ''
    }`;
  }
  return '';
};

export {
  getFormattedDate,
  getUTCTime,
  getUtcEndTime,
  getEndTime,
  getUtcDate,
  getDateFromUtc,
  getTodayWithTimeZone,
  getDuration,
  getFullDuration
};
