import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealId: '',
  isLoading: false
};

export const downloadAnalyticsReportSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    updateDownloadAnalyticsDealId: (state, { payload }) => {
      state.dealId = payload;
    },
    updateDownloadAnalyticsIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    }
  }
});

export const {
  updateDownloadAnalyticsDealId,
  updateDownloadAnalyticsIsLoading
} = downloadAnalyticsReportSlice.actions;

export default downloadAnalyticsReportSlice.reducer;
