import en from '@public/locales/en/translation.json';

import i18n from '../i18n';

export type Join<S1, S2> = S1 extends string
  ? S2 extends string
    ? `${S1}.${S2}`
    : never
  : never;

export type Paths<T> = {
  [K in keyof T]: T[K] extends Record<string, unknown>
    ? Join<K, Paths<T[K]>>
    : K;
}[keyof T];

export type TypeEnTranslation = Paths<typeof en>;

export const translate = (key: TypeEnTranslation, variables?: object): string =>
  i18n.t(key, variables);

export const translateDynamic = (key: string, variables?: object): string =>
  i18n.t(key, variables);
