import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import restApi from '@services/api';

import rootReducer from './reducers';

export const store = configureStore({
  reducer: {
    rootReducer,
    [restApi.reducerPath]: restApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(restApi.middleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
