/* eslint-disable tailwindcss/no-custom-classname */
import React, { FC } from 'react';
import { UpArrow } from '@assets/icons';

import { AnalyticsCardPropTypes } from './types';

const AnalyticsCard: FC<AnalyticsCardPropTypes> = ({
  title,
  value,
  icon,
  growth,
  subtitle,
  customContainerStyle,
  customStyle
}) => {
  const checkIsNullOrNA = (val: string) => {
    return val === null || val === undefined || val === 'NA';
  };

  const hideGrowthPercentage = checkIsNullOrNA(growth);

  return (
    <div
      className={`p-6 w-full min-w-[362px] min-h-[156px] max-w-[358px] 1.25xl:p-3 1.25xl:min-w-[362px] 1.25xl:max-w-[483px]  rounded-xl border ${
        customContainerStyle ? customContainerStyle : '1.25xl:min-h-[189px]'
      }`}>
      <div
        className={`flex justify-between h-full ${
          customStyle ? customStyle : 'flex-col'
        }`}>
        <div className="flex flex-row gap-4 items-center">
          <div className="flex justify-center items-center w-12 h-12 bg-[#10182814] rounded-full">
            {icon}
          </div>
          <div>
            <div className="text-base font-medium leading-6 text-mirage xl:text-xl">
              {title}
            </div>
            <div className="text-sm font-normal leading-6 text-gray-600">
              {subtitle}
            </div>
          </div>
        </div>
        {value && (
          <div className="text-xl font-semibold leading-6 text-mirage xl:text-3xl">
            {value}
          </div>
        )}
        <div>
          {growth && !hideGrowthPercentage ? (
            <div className="flex gap-1 items-center min-w-[136px]">
              <div
                className={`flex text-sm font-medium ${
                  parseFloat(growth) > 0 ? 'text-tealGreen' : 'text-red'
                }`}>
                <UpArrow
                  fill={parseFloat(growth) > 0 ? 'text-tealGreen' : 'text-red'}
                  style={{
                    transform: parseFloat(growth) > 0 ? '' : 'rotate(180deg)'
                  }}
                />
                {` ${growth}%`}
              </div>
              <div className="text-sm font-normal text-gray-600">
                {'vs last month'}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
