export const tableHeaderMapper = {
  companyName: 'company_name',
  dealCode: 'company_deal_code',
  leadBy: 'syndicate_name',
  status: 'status',
  startDate: 'start_time',
  endDate: 'expires_at',
  dealType: 'type',
  investedStatus: 'investment_percentage',
  committedStatus: 'commitment_percentage'
};
