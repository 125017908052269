import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  TextField,
  Divider,
  Paper,
  Autocomplete,
  Box,
  AutocompleteCloseReason
} from '@mui/material';
import { isEmpty } from 'lodash';

import { colors } from '@constants/colors';
import { SHORT_FIELD_MAX_LENGTH } from '@constants/common';

import { AutoCompleteProps, OptionsLOVType } from './types';

const AutocompleteDropdown: FC<AutoCompleteProps> = props => {
  const {
    name,
    placeholder,
    control,
    options,
    errors,
    footerElement,
    selectedItem = null,
    onChangeHandler = () => null,
    isDisabled = false,
    wrapperClass = '',
    handleInputChange,
    inputValue,
    clearOnBlur,
    description,
    maxLength,
    autoFocusField = false,
    showCharacterLimit = false,
    onFocus
  } = props;

  const autoCompleteCustomStyle = {
    width: '100%',
    minHeight: '52px',
    '& .MuiAutocomplete-endAdornment': {
      top: `${showCharacterLimit ? 'calc(50% - 20px)' : 'calc(50% - 12px)'}`,
      marginRight: '16px'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#000000ab'
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: colors.primaryBlue
    },
    '& .MuiOutlinedInput-root': {
      padding: '8px',
      paddingBottom: `${showCharacterLimit ? '16px' : '8px'}`,
      '& fieldset': {
        borderColor: colors.gray88,
        borderRadius: '5px',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primaryBlue,
        borderRadius: '5px',
        borderWidth: '1px'
      }
    }
  };

  const [selectedValue, setSelectedValue] = useState<OptionsLOVType | null>(
    selectedItem
  );
  const [open, setOpen] = useState<boolean>(false);

  const autocompleteRef = useRef<HTMLDivElement | null>(null);

  const handleOptionSelect = (value: OptionsLOVType) => {
    setSelectedValue(value);
    onChangeHandler(value);
    setOpen(false);
  };

  const onAutoCompleteFocus = () => {
    setOpen(true);
    onFocus && onFocus();
  };

  const onCloseEvent = (
    _: React.SyntheticEvent<Element, Event>,
    reason: AutocompleteCloseReason
  ) => {
    // The footer onclick is not working since on clicking the footer
    // The onClose prop of autocomplete works first and then the event stops propagating
    // Also when we click the footer the reason for close is 'blur'
    // Closing of popup on blur is handled using the below hooks
    // Hence we close the popup incases other than blur
    // Fix : https://linear.app/infinyte-club/issue/ENG-3217/dashboard-unable-to-add-syndicate-during-deal-creation
    if (reason !== 'blur') {
      setOpen(false);
    }
  };

  useEffect(() => {
    setSelectedValue(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div ref={autocompleteRef} className={`${wrapperClass}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <div className="relative">
            {showCharacterLimit && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '6px', // Adjust the bottom position as needed
                  right: '12px',
                  zIndex: 2 // Increase zIndex to make sure it is above the dropdown
                }}>
                <div className="text-xs font-normal leading-3 text-gray02">{`${
                  inputValue?.length ?? 0
                }/${maxLength || SHORT_FIELD_MAX_LENGTH} characters`}</div>
              </div>
            )}
            <Autocomplete
              sx={autoCompleteCustomStyle}
              open={open}
              options={options}
              autoHighlight={true}
              autoComplete={false}
              clearOnBlur={clearOnBlur}
              inputValue={inputValue}
              onFocus={onAutoCompleteFocus}
              onOpen={() => setOpen(true)}
              onClose={onCloseEvent}
              onChange={(_, value: OptionsLOVType) => {
                handleOptionSelect(value);
                onChange(value?.id);
              }}
              onInputChange={handleInputChange}
              disableCloseOnSelect={true}
              ListboxProps={{ style: { maxHeight: '150px' } }}
              renderOption={(renderingprops, option) => {
                return (
                  <div
                    key={option.id}
                    className="overflow-y-auto text-[16px] font-normal leading-6">
                    <Box
                      py={6}
                      px={16}
                      component="li"
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 }
                      }}
                      {...renderingprops}>
                      {option.namePrefixElement}
                      {option.name}
                    </Box>
                  </div>
                );
              }}
              PaperComponent={({ children }) => (
                <Paper>
                  {children}
                  {footerElement && (
                    <div className="sticky bottom-0 py-1">
                      <Divider />
                      {footerElement}
                    </div>
                  )}
                </Paper>
              )}
              value={selectedValue}
              getOptionLabel={(option: OptionsLOVType) => {
                return option.name;
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  error={Boolean(
                    !isEmpty(errors) &&
                      (errors[name] as { message?: string })?.message
                  )}
                  label={placeholder}
                  name={name}
                  onFocus={() => setOpen(true)}
                  helperText={
                    !isEmpty(errors) && errors[name]
                      ? (errors[name] as { message?: string })?.message
                      : description
                  }
                  inputProps={{
                    ...params.inputProps,
                    maxLength: maxLength || 250
                  }}
                  autoFocus={autoFocusField}
                />
              )}
              disabled={isDisabled}
            />
          </div>
        )}
      />
    </div>
  );
};

export default AutocompleteDropdown;
