import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ToastObject, ToastTypes } from '@type/toast';
import { CloseIcon } from '@assets/icons';

const displayToast = (
  type: ToastTypes,
  message: string,
  subText?: string,
  position?: ToastPosition,
  disableAutoClose?: boolean,
  toastId?: string
) => {
  return toast(
    ({ closeToast }) => (
      <div
        className={`flex w-[370px] flex-row items-center justify-between rounded-xl md:w-[483px] 
        ${ToastObject[type].bg} border border-solid 
        ${ToastObject[type].border} px-6 py-5 ${ToastObject[type].customStyles}`}>
        <div className="flex flex-row items-center w-[98%]">
          <div className="flex items-center mr-1.5">
            {ToastObject[type].Icon}
          </div>
          <div className="items-center ml-4 w-[98%]">
            <p className="text-base font-normal">{message}</p>
            {subText && <p className="text-sm font-normal">{subText}</p>}
          </div>
        </div>

        <button
          onClick={closeToast}
          className="flex self-center p-2 h-[28px] bg-white rounded-full shadow-md">
          <CloseIcon height={12} width={12} />
        </button>
      </div>
    ),
    {
      position: position ?? toast.POSITION.TOP_CENTER,
      autoClose: disableAutoClose ? false : 5000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      pauseOnFocusLoss: true,
      draggable: true,
      progress: undefined,
      toastId: toastId
    }
  );
};

export default displayToast;
