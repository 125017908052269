import React, { FC, useCallback } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { ToastTypes } from '@type/toast';
import { translate } from '@utils/locale';
import { FileUploaderIcon } from '@assets/icons';
import { SUPPORTED_FILE_FORMATS } from '@constants/common';
import displayToast from '@components/toast/customToast';

import { FileDropZoneProps } from './types';

const FileDropZone: FC<FileDropZoneProps> = props => {
  const {
    onFileUpload,
    maxSizeInMb,
    supportedFormats = SUPPORTED_FILE_FORMATS,
    hasError = false,
    isDisabled = false,
    customUploaderStyle,
    hideUploadFileIcon = false,
    customUploadText
  } = props;

  const handleOnDrop = useCallback(
    async acceptedFiles => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        onFileUpload(file);
      }
    },

    [onFileUpload, maxSizeInMb]
  );

  const handleShowError = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
      displayToast(ToastTypes.ERROR, 'Invalid file type');
    }
    // eslint-disable-next-line no-console
    console.log(event);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    accept: supportedFormats,
    disabled: isDisabled,
    onDropRejected: handleShowError
  });

  const renderText = () => {
    const formatToCapitalized = (format: string) =>
      format.slice(1).toUpperCase();
    const formattedStrings = Object.entries(supportedFormats).map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      ([_, extensions]) => extensions.map(formatToCapitalized).join(', ')
    );

    if (formattedStrings.length > 1)
      return `${
        formattedStrings.slice(0, -1).join(', ') +
        translate('fileUpload.or') +
        formattedStrings.slice(-1) +
        translate('fileUpload.maximumMb', {
          size: maxSizeInMb
        })
      } `;
    return `${
      formattedStrings.slice(-1) +
      translate('fileUpload.maximumMb', {
        size: maxSizeInMb
      })
    }`;
  };

  return (
    <div
      {...getRootProps()}
      className={`flex justify-start items-center p-5 w-full min-h-[93px] rounded-lg border ${customUploaderStyle}
         ${hasError ? 'border-red' : 'border-gray-200'} border-dashed ${
        isDisabled ? 'cursor-default opacity-70' : 'cursor-pointer'
      }
         ${isDragActive ? 'bg-gray-300' : 'bg-white'}`}>
      <input {...getInputProps()} />
      {!hideUploadFileIcon ? (
        <div className="p-2">
          <FileUploaderIcon />
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col ml-4">
        <>
          <div className="mb-2 text-base font-normal">
            <span className="text-primary underline">
              {translate('fileUpload.upload')}
            </span>
            <span className="mx-1 text-black/90">
              {translate('fileUpload.or')}
            </span>
            <span className="text-black/80">
              {translate('fileUpload.dragndrop')}
            </span>
          </div>
          <div>
            <span className="text-sm text-black/60">
              {customUploadText ? customUploadText : renderText()}
            </span>
          </div>
        </>
      </div>
    </div>
  );
};

export default FileDropZone;
