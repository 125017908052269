import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aifUnitPrice: ''
};

export const aifUnitPrice = createSlice({
  name: 'aifUnitPrice',
  initialState,
  reducers: {
    resetAifUnitPriceValues: () => ({
      aifUnitPrice: ''
    }),
    updateAifUnitPriceValues: (_, { payload }) => ({
      aifUnitPrice: payload
    })
  }
});

export const { resetAifUnitPriceValues, updateAifUnitPriceValues } =
  aifUnitPrice.actions;

export default aifUnitPrice.reducer;
