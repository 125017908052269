import { Mutex } from 'async-mutex';
import { ClientError, GraphQLClient } from 'graphql-request';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/dist/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { TokenExpired } from '@type/error';
import { AuthorizationValues } from '@constants/auth';
import { ApiTags } from '@constants/common';
import { logout } from '@utils/auth';

import { ExtraOptions, GraphQLArgs, RequestCredentials } from './type';
import { getLocalData, setLocalData } from './localStorage';
import { replaceEmptyStrings } from './services';

const mutex = new Mutex();

// for rest endpoints

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API_ENDPOINT}/`,
  prepareHeaders: (headers, { endpoint }) => {
    if (!['refresh', 'uploadFileToS3'].includes(endpoint)) {
      headers.set(
        'Authorization',
        `Bearer ${getLocalData(AuthorizationValues.ACCESS_TOKEN)}`
      );
      headers.set('Accept-Encoding', 'gzip');
    }
    return headers;
  }
  // credentials: RequestCredentials.INCLUDE
});

const baseQueryWitAuth: BaseQueryFn = async (args, api, extraOptions) => {
  const newArgs =
    api.endpoint === 'uploadFileToS3'
      ? { ...args }
      : { ...args, body: replaceEmptyStrings(args.body) };

  try {
    await mutex.waitForUnlock();
    let result = await baseQuery(newArgs, api, extraOptions);
    if (
      String(result.error?.status) === TokenExpired.TOKEN_EXPIRED_STATUS_CODE
    ) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await fetch(
            // eslint-disable-next-line max-len
            `${process.env.REACT_APP_BASE_API_ENDPOINT}/${process.env.REACT_APP_BASE_API_VERSION}/authentication/tokens/refresh`,
            {
              method: 'POST',
              credentials: RequestCredentials.INCLUDE,
              mode: 'cors',
              headers: new Headers({
                'Refresh-Token': getLocalData(AuthorizationValues.REFRESH_TOKEN)
              })
            }
          );
          if (refreshResult.status === 200) {
            const refreshResponse = await refreshResult.json();
            setLocalData(
              AuthorizationValues.ACCESS_TOKEN,
              refreshResponse.result.accessToken
            );
            setLocalData(
              AuthorizationValues.REFRESH_TOKEN,
              refreshResponse.result.refreshToken
            );
            result = await baseQuery(newArgs, api, extraOptions);
          } else {
            logout();
          }
        } catch (error) {
          logout();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(newArgs, api, extraOptions);
      }
    } else if (
      String(result.error?.status) === TokenExpired.FORBIDDEN_USER_CODE
    ) {
      logout();
    }
    return result;
  } catch (error) {
    //handle Api Error
    return error;
  }
};

export const restApi = createApi({
  reducerPath: 'restApi',
  baseQuery: baseQueryWitAuth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [
    ApiTags.deal,
    ApiTags.dealList,
    ApiTags.syndicate,
    ApiTags.syndicateList,
    ApiTags.dealInvite,
    ApiTags.syndicateInvite,
    ApiTags.getUserDocuments,
    ApiTags.getDealDocuments,
    ApiTags.getNomineeDetails,
    ApiTags.getUserDetails,
    ApiTags.getKycDetails,
    ApiTags.recordInvestment,
    ApiTags.teamMember,
    ApiTags.rounds,
    ApiTags.sector,
    ApiTags.investor,
    ApiTags.companyConfiguration,
    ApiTags.companyList,
    ApiTags.getCompany,
    ApiTags.opportunityList,
    ApiTags.opportunity,
    ApiTags.userScreening
  ]
});

// For graphql APi
export const client = new GraphQLClient(
  process.env.REACT_APP_BASE_API_ENDPOINT as string,
  {
    mode: 'cors'
    // credentials: RequestCredentials.INCLUDE
  }
);

const gqlBaseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: (headers, { endpoint }) => {
    if (endpoint !== 'refresh') {
      headers.set(
        'Authorization',
        `Bearer ${getLocalData(AuthorizationValues.ACCESS_TOKEN)}`
      );
      headers.set('Accept-Encoding', 'gzip');
    }
    return headers;
  }
});

const isTokenExpired = (error: string) =>
  error.toLowerCase().includes(TokenExpired.TOKEN_EXPIRED_STATUS_CODE);

const customFetchGQLBaseQuery: BaseQueryFn<
  GraphQLArgs, // Args
  unknown, // Result
  unknown, // Error
  ExtraOptions & Partial<Pick<ClientError, 'request' | 'response'>>, // DefinitionExtraOptions
  object // Meta
> = async (arg, api, extraOptions) => {
  try {
    await mutex.waitForUnlock();
    let result = await gqlBaseQuery(arg, api, extraOptions);
    if (result.error?.message && isTokenExpired(result.error.message)) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await fetch(
            // eslint-disable-next-line max-len
            `${process.env.REACT_APP_BASE_API_ENDPOINT}/${process.env.REACT_APP_BASE_API_VERSION}/authentication/tokens/refresh`,
            {
              method: 'POST',
              credentials: RequestCredentials.INCLUDE,
              mode: 'cors',
              headers: new Headers({
                'Refresh-Token': getLocalData(AuthorizationValues.REFRESH_TOKEN)
              })
            }
          );
          if (refreshResult.status === 200) {
            const refreshResponse = await refreshResult.json();
            setLocalData(
              AuthorizationValues.ACCESS_TOKEN,
              refreshResponse.result.accessToken
            );
            setLocalData(
              AuthorizationValues.REFRESH_TOKEN,
              refreshResponse.result.refreshToken
            );
            result = await gqlBaseQuery(arg, api, extraOptions);
          } else {
            logout();
          }
        } catch (error) {
          logout();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await gqlBaseQuery(arg, api, extraOptions);
      }
    } else if (
      result.error?.message &&
      result.error.message.includes(TokenExpired.FORBIDDEN_USER)
    ) {
      logout();
    }
    return result;
  } catch (error) {
    //Handle api error
    return error;
  }
};

export const graphqlApi = createApi({
  reducerPath: 'graphqlApi',
  baseQuery: customFetchGQLBaseQuery,
  endpoints: () => ({})
});

export default restApi;
